import './App.css'
import video from './assets/videos/rose-video.mp4'
import idea from './assets/img/idea.png'
import team from './assets/img/team.png'
import results from './assets/img/results.png'
import GoogleMap from 'google-maps-react-markers'
import rose from './assets/img/sightImg/roses.jpg'
import mapObjects from './data/map/objects'
import Marker, { defaultProps } from './pages/DigitalResources/Marker'
import { useState } from 'react'

const Home = () => {
	const [mapReady, setMapReady] = useState(false)

	const onMarkerClick = () => {
		window.location = "/digital-resources"
	}
	const onGoogleApiLoaded = ({ map, maps }) => {
		console.log("called");
		setMapReady(true)
	}
	return (
		<div className="Home">
		<div className="Landing">
			<div className="Landing-image"></div>
			{/* <video className="Landing-video" src={video} autoPlay muted loop></video> */}
			<h1>Интегрирано дигитално представяне на културното наследство на обл.Стара Загора</h1>
		</div>
		<div className="Keypoints">
			<ul className="Keypoints-list">
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={idea}></img>
						<h2>Идеята</h2>
						<p>Кратко описание за идеята.</p>
					</div>
				</li>
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={team}></img>
						<h2>Екипът</h2>
						<p>Кратко представяне на екипа.</p>
					</div>
				</li>
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={results}></img>
						<h2>Резултати</h2>
						<p>Кратко представяне на резултатите.</p>
					</div>
				</li>
			</ul>
		</div>
		<div className="Map-container">
			<div className="Landing-map">
				<GoogleMap
					apiKey="AIzaSyBDJiCvHuyZgIkVWrDXAgJEBALc3nnmy0c"
					mapMinHeight='100vh'
					options={{
						scrollwheels: false,
					}}
					defaultCenter={defaultProps.center}
					defaultZoom={defaultProps.zoom}
					onGoogleApiLoaded={onGoogleApiLoaded}
				>
					{mapObjects.map((object, index) => (
						<Marker
							key={index}
							lat={object.lat}
							lng={object.lng}
							markerId={object.name}
							onClick={() => onMarkerClick(index)}
							// draggable={true}
							// onDragStart={(e, { latLng }) => {}}
							// onDrag={(e, { latLng }) => {}}
							// onDragEnd={(e, { latLng }) => {}}
						/>
					))}
				</GoogleMap>
			</div>
		</div>
		<div className="News-landing">
			<h1>Новини</h1>
			<ul className="News-list">
				<li className="News-list-item">
					<h2>Новина 1</h2>
					<p>
						Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
						dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
						Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
						sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
					</p>
				</li>
				<li className="News-list-item">
					<h2>Новина 2</h2>
					<p>
						Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
						dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
						Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
						sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
					</p>
				</li>
				<li className="News-list-item">
					<h2>Новина 3</h2>
					<p>
						Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
						dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
						Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
						sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
					</p>
				</li>
			</ul>
		</div>
		</div>
	)
}

export default Home;