import * as THREE from "three";
import { createWalls } from "./walls";
import { createPaintings } from "./paintings";
import { createBoundingBoxes } from "./boundingBox";
import { setupRendering } from "./rendering";
import { scene, setupScene } from "./scene";
import { setupEventListeners } from "./eventListeners";
import { setupLighting } from "./lighting";
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

// Create texture for the floor
const textureLoader = new THREE.TextureLoader();
const planeTexture = textureLoader.load("virtualMuseum/textures/floor.jpg");
planeTexture.wrapS = THREE.RepeatWrapping;
planeTexture.wrapT = THREE.RepeatWrapping;
planeTexture.repeat.set(5, 5);

// Create the floor
const planeMaterial = new THREE.MeshBasicMaterial({
	map: planeTexture,
	side: THREE.DoubleSide
});
const planeGeometry = new THREE.PlaneGeometry(50, 50);
const floorPlane = new THREE.Mesh(planeGeometry, planeMaterial);
floorPlane.rotation.x = Math.PI / 2;
floorPlane.position.y = -Math.PI;
scene.add(floorPlane);

// Create the ceiling
const ceilingMaterial = new THREE.MeshLambertMaterial({
	color: 'white'
})
const ceilingGeometry = new THREE.PlaneGeometry(50, 50);
const ceilingPlane = new THREE.Mesh(ceilingGeometry, ceilingMaterial);
ceilingPlane.rotation.x = Math.PI / 2;
ceilingPlane.position.y = 10;
scene.add(ceilingPlane);

export const addObjectsToScene = (scene, objects) => {
	objects.forEach((object) => {
	  scene.add(object);
	});
};
let { camera, controls, renderer } = setupScene();

// Create the walls
const walls = createWalls(scene, textureLoader);
const paintings = createPaintings(scene);
const lighting = setupLighting(scene, paintings);

// Create environment for lightning
const environment = new RoomEnvironment();
const pmremGenerator = new THREE.PMREMGenerator( renderer );
scene.environment = pmremGenerator.fromScene( environment ).texture;
environment.dispose();

addObjectsToScene(scene, paintings);
createBoundingBoxes(walls);
createBoundingBoxes(paintings);

setupEventListeners();

window.addEventListener("load", () => {
	setupRendering(scene, camera, renderer, paintings, controls, walls);
})

export { camera, controls, renderer };