import * as THREE from "three";
import { displayPaintingInfo, hidePaintingInfo } from "./paintingInfo.js";
import { updateMovement } from "./movement.js";

export const setupRendering = (
	scene,
	camera,
	renderer,
	paintings,
	controls,
	walls
) => {
	const clock = new THREE.Clock();

	const render = () => {
		const delta = clock.getDelta();

		updateMovement(delta, controls, camera, walls);

		const distanceThreshold = 15;

		let paintingToShow;
		paintings.forEach((painting) => {
			const distanceToPainting = camera.position.distanceTo(painting.position);
			if (distanceToPainting < distanceThreshold) {
				paintingToShow = painting;
			}
		});

		if (paintingToShow) {
			// if there is a painting to show
			displayPaintingInfo(paintingToShow.info);
		} else {
			hidePaintingInfo();
		}

		renderer.gammaOutput = true;
		renderer.gammaFactor = 2.2;

		renderer.render(scene, camera);
		requestAnimationFrame(render);
	};
	render();
};
