import { Link, useParams } from 'react-router-dom';
import './Sights.css';
import { useState, useEffect } from 'react';

const Sights = () => {
	const [sights, setSights] = useState([]);
	const { themeId } = useParams();

	useEffect(() => {
		const importSights = async () => {
			const importedSight = await import(`../../data/sights/theme${themeId}/sights.js`);
			const sights = importedSight.default;
			setSights(sights);
		}

		importSights();
	}, themeId);

	const themeStyle = (src) => ({
		backgroundImage: 'url(' + src + ')',
		backgroundPosition: 'centre'
	})

	return (
		<div className="Sights">
			<div className="Sights-header">

			</div>
			<ul className="Sights-list">
				{sights && sights.map((sight, id) => (
					<Link to={`/sights/${id}`} state={{ data: sight }}>
						<li className="Sight-item" style={themeStyle(sight.img)}>
							<h2>{sight.name}</h2>
							<p>{sight.info}</p>
						</li>
					</Link>
				))}
			</ul>
		</div>
	)
}

export default Sights;