import * as THREE from 'three';

export const keysPressed = {
	ArrowUp: false,
	ArrowDown: false,
	ArrowLeft: false,
	ArrowRight: false,
	w: false,
	a: false,
	s: false,
	d: false,
};

export const updateMovement = (delta, controls, camera, walls) => {
	const moveSpeed = 5 * delta; 

	const previousPosition = camera.position.clone();

	if (keysPressed.ArrowRight || keysPressed.d) {
		controls.moveRight(moveSpeed);
	}
	if (keysPressed.ArrowLeft || keysPressed.a) {
		controls.moveRight(-moveSpeed);
	}
	if (keysPressed.ArrowUp || keysPressed.w) {
		controls.moveForward(moveSpeed);
	}
	if (keysPressed.ArrowDown || keysPressed.s) {
		controls.moveForward(-moveSpeed);
	}

	if (checkCollision(camera, walls)) {
		camera.position.copy(previousPosition);
	}
};

export const checkCollision = (camera, walls) => {
	const playerBoundingBox = new THREE.Box3();
	const cameraWorldPosition = new THREE.Vector3();
	camera.getWorldPosition(cameraWorldPosition);
	playerBoundingBox.setFromCenterAndSize(
		// set the playerBoundingBox to the camera's world position and size. The size is 1, 1, 1 because the camera is a single point.
		// setFromCenterAndSize takes two parameters: center and size. The center is a Vector3 that represents the center of the bounding box. The size is a Vector3 that represents the size of the bounding box. The size is the distance from the center to the edge of the bounding box in each direction. So, if the size is 1, 1, 1, the bounding box will be 2 units wide, 2 units tall, and 2 units deep. If the size is 2, 2, 2, the bounding box will be 4 units wide, 4 units tall, and 4 units deep.
		cameraWorldPosition, // center
		new THREE.Vector3(1, 1, 1) // size
	);

	for (let i = 0; i < walls.children.length; i++) {
		const wall = walls.children[i];
		if (playerBoundingBox.intersectsBox(wall.BoundingBox)) {
			return true;
		}
	}

	return false;
};
