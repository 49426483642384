import React from 'react';
import './News.css';

const News = () => {
	return (
		<div className="News">
			<h1>Новини</h1>
		</div>
	)
}

export default News;