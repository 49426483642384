import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Link, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Sights from './pages/Sights/Sights';
import DigitalResources from './pages/DigitalResources/DigitalResources';
import Project from './pages/About/Project';
import AreaInfo from './pages/About/AreaInfo';
import Sight from './pages/Sights/Sight';
import Themes from './pages/Sights/Themes';
import Contact from './pages/Contact/Contact';
import News from './pages/News/News';

import menuIcon from './assets/img/menu.png'
import leftArrow from './assets/img/left-arrow.png'

const Header = () => {
	const location = useLocation();
	const [useLandingStyle, setUseLandingStyle] = useState([false]);
	const [openMenuStyle, setOpenMenuStyle] = useState({});
	const [openMenuWrapperStyle, setOpenMenuWrapperStyle] = useState({});
	const [openMenuIcon, setOpenMenuIcon] = useState(menuIcon);

	useEffect(() => {
		if (location.pathname == '/')
			setUseLandingStyle(true);
		else
			setUseLandingStyle(false);
	})

	const getLandingPageStyle = () => ({
		background: 'none',
		position: 'absolute'
	});

	const expandMenu = () => {
		if (openMenuStyle.left === 0) { // opened
			setOpenMenuStyle({
				left: '-40vw'
			})
			setOpenMenuIcon(
				menuIcon
			)
		}
		else { // closed
			setOpenMenuStyle({
				left: 0,
			})
			setOpenMenuIcon(
				leftArrow
			)
		}
	}

	return (
		<header className="App-header" style={useLandingStyle ? getLandingPageStyle() : {}}>
			<img className="Menu-icon" onClick={expandMenu} src={openMenuIcon}/>
			<Link to={"/"}><img src={require("./assets/img/logo.png")} /></Link>
			{/* <div className="Menu-wrapper" style={openMenuWrapperStyle}>
				<div className="Menu" style={openMenuStyle}></div>
			</div> */}
			<div className="App-header-nav-items">
				<ul>
					<li>
						<Link to={"/themes"} className='App-header-nav-item'>На фокус</Link>
					</li>
					<li>
						<Link to={"/digital-resources"} className='App-header-nav-item'>Дигитални ресурси</Link>
					</li>
					<li>
						<Link to={"/area"} className='App-header-nav-item'>За областта</Link>
					</li>
					<li>
						<Link to={"/project"} className='App-header-nav-item'>За проекта</Link>
					</li>
					<li>
						<Link to={"/links"} className='App-header-nav-item'>Връзки</Link>
					</li>
					<li>
						<Link to={"/news"} className='App-header-nav-item'>Новини</Link>
					</li>
				</ul>
			</div>
		</header>
	)
}

const Footer = () => {
	return (
		<div className="App-footer">
			<h3>Контакти:</h3>
		</div>
	)
}
const App = () => {

	return (
		<div className="App">
			<BrowserRouter>
			<Header/>
			<Routes>
				<Route path="/" element={<Home/>}/>
				<Route path="/themes" element={<Themes/>}/>
				<Route exact path="/themes/:themeId" element={<Sights/>}/>
				<Route path="/sights/:sightId" element={<Sight/>}/>
				<Route path="/digital-resources" element={<DigitalResources/>}/>
				<Route path="/project" element={<Project/>}/>
				<Route path="/area" element={<AreaInfo/>}/>
				<Route path="/links" element={<Contact/>}/>
				<Route path="/news" element={<News/>}/>
			</Routes>
			<Footer/>
			</BrowserRouter>
		</div>
	);
}

export default App;
