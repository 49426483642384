import "./Sights.css"
import { useLocation } from "react-router-dom";

const Sight = () => {
	const location = useLocation();
	const sightData =location.state?.data;
	console.log(sightData);
	return (
		<div className="Sight">
			<div className="Sight-info">
				<h1>{sightData.name}</h1>
				<p>{sightData.description}</p>
			</div>
			<img src={process.env.PUBLIC_URL + sightData.img} alt=""/>
		</div>
	)
}

export default Sight;