// Display painting info in the DOM
export const displayPaintingInfo = (info) => {
	const infoElement = document.getElementById('painting-info');
	if (!infoElement)
		return;
	// Set the html content inside info element
	infoElement.innerHTML = `
		<h3>${info.title}</h3>
		<p><em>Художник: ${info.artist}</em></p>
		<p>${info.description}</p>
	`;
	infoElement.classList.add('show');
	infoElement.style.padding = "20px";
};

// Hide painting info in the DOM
export const hidePaintingInfo = () => {
	const infoElement = document.getElementById('painting-info');
	if (!infoElement)
		return;
	infoElement.classList.remove('show');
};