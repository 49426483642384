import React from 'react';
import './Contact.css';

const Contact = () => {
	return (
		<div className="Contact">
			<h1>Връзки</h1>
		</div>
	)
}

export default Contact;