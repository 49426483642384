const themes = [
	{
		name: "Археологично наследство",
		children: ["Гробници", "Мегалит Бузовград", "Стара Загора"],
		img: "/sightImg/tomb.jpg",
		hasChildren: true,
	},
	{
		name: "Архитектура",
		children: ["Маршрут", "Стара Загора и Казанлък", "Чирпан и Павел Баня"],
		img: "/sightImg/museum.jpg",
	},
	{
		name: "Бузлуджа",
		img: "/sightImg/ruins.jpg",
	},
	{
		name: "Исторически паметници",
		children: ["Шипка", "Паметник Бранителите", "Мавзолей Стара Загора"],
		img: "/sightImg/monuments.jpg",
	},
	{
		name: "Църкви и манастири",
		children: ["Мъглижки манастир", "манастир Св. Атанасий", "Хилендарски метох"],
		img: "/sightImg/monastery.jpg",
	},
	{
		name: "Творци и къщи-музеи",
		children: ["Писатели", "Художници", "Композитори"],
		img: "/sightImg/houses.jpg",
	},
	{
		name: "Рози",
		img: "/sightImg/roses.jpg",
	},
	{
		name: "Лютиери",
		img: "/sightImg/lutier.jpg",
	},
	{
		name: "Традиции и занаяти",
		children: ["Магарето", "Кукери"],
		img: "/sightImg/kuker.jpg",
	},
	{
		name: "Фестивали",
		children: ["Лавандула", "Музикални фестивали", "Литературни дни", "Бузлуджа"],
		img: "/sightImg/festival.jpg",
	},
]

export default themes;