import { controls } from "./main.js";
import { keysPressed } from "./movement.js"; // import the keysPressed object


let lockPointer = true;
let showMenuOnUnlock = false;

export const setupEventListeners = () => {
	document.addEventListener(
		"keydown",
		(event) => onKeyDown(event),
		false
	);
	document.addEventListener(
		"keyup",
		(event) => onKeyUp(event),
		false
	);
};

const onKeyDown = (event) => {
	event.preventDefault();

	if (event.key in keysPressed) {
		keysPressed[event.key] = true;
	}
}

const onKeyUp = (event) => {
	if (event.key in keysPressed) {
		keysPressed[event.key] = false;
	}
}
