const events = [
    {
        date: "Feb 24 2024",
        title: "Събитие от календара",
        description: "Описание на събитието от календара."
    },
    {
        date: "Feb 25 2024",
        title: "Друго събитие от календара",
        description: "Описание на другото събитие от календара."
    }
]

export default events;