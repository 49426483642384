import * as THREE from "three";

const createPainting = (imageURL, width, height, position) => {
	const textureLoader = new THREE.TextureLoader();
	const paintingTexture = textureLoader.load(imageURL);
	const paintingMaterial = new THREE.MeshBasicMaterial({
		map: paintingTexture
	});
	const paintingGeometry = new THREE.PlaneGeometry(width, height);
	const painting = new THREE.Mesh(paintingGeometry, paintingMaterial);
	painting.position.set(position.x, position.y, position.z);
	return painting;
}

export const createPaintings = (scene) => {
	let paintings = [];

	const painting1 = createPainting(
		"virtualMuseum/artworks/0.jpg",
		8,
		5,
		new THREE.Vector3(0, 5, -19.99)
	);
	painting1.info = {
		title: "Звездна нощ",
		artist: "Ван Гог",
		description: "Картината 'Звездна нощ' е едно от най-разпознаваемите платна в историята на изобразителното изкуство. Картината показва нощта над Сан Реми. Нарисувана през юни 1889 г., а от 1941 се съхранява в Музея за съвременно изкуство в Ню Йорк."
	}
	paintings.push(painting1);

	const painting2 = createPainting(
		"virtualMuseum/artworks/20.jpg",
		5,
		6.5,
		new THREE.Vector3(0, 5, 19.99)
	);
	painting2.rotation.y = Math.PI;
	painting2.info = {
		title: "Слънчогледите",
		artist: "Ван Гог",
		description: "Слънчогледите е името на две серии от натюрморти на холандския художник Винсент ван Гог. Първата серия е създадена в Париж през 1887 година и показва легнали слънчогледи, докато втората серия, създадена в Арл, показва букети от слънчогледи във вази."
	}
	paintings.push(painting2);

	const painting3 = createPainting(
		"virtualMuseum/artworks/23.jpg",
		8,
		5,
		new THREE.Vector3(0, 5, -10)
	);
	painting3.position.set(-19.99, 5, 0);
	painting3.rotation.y = Math.PI/2;
	painting3.info = {
		title: "Цъфнали бадеми",
		artist: "Ван Гог",
		description: "Цъфтящите дървета са специални за Винсент ван Гог. При пристигането си в Арл през пролетта на 1888 година той е очарован от дръвчетата потънали в цвят."
	}
	paintings.push(painting3);

	const painting4 = createPainting(
		"virtualMuseum/artworks/29.jpg",
		5,
		6.5,
		new THREE.Vector3(0, 5, -10)
	);
	painting4.position.set(19.99, 5, 0);
	painting4.rotation.y = -Math.PI/2;
	painting4.info = {
		title: "Автопортрет с превързано ухо и лула",
		artist: "Ван Гог",
		description: "„В живота, както и в изкуството, аз мога да се справя сам със себе си и без Господ Бог. Но не мога да съществувам – аз, страдащият човек – без нещо по-голямо от мен, нещо, което изпълва целия ми живот – без творческата сила...” – Ван Гог."
	}
	paintings.push(painting4);

	return paintings;
}