import museum from "../../assets/img/info.png"

const Marker = (props) => {
	return <img
		className={props.className}
		onClick={props.onClick}
		src={museum}
		lat={props.lat}
		lng={props.lng}
		style={{ fontSize: 40 }}
		alt={props.markerId}
		width={35}
		height={35}
		{...props}
	/>
}

export const defaultProps = {
	center: {
		lat: 42.426470703484895,
		lng: 25.63516055945506
	},
	zoom: 10
};

export default Marker;