import "./About.css";

const Project = () => {
	return (
		<div className="Project">
			<h1>Идеята</h1>
			<h2>
				Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
				dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
				Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
				sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
			</h2>
			<h1>Екипът</h1>
			<h2>
				Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
				dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
				Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
				sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
			</h2>
			<h1>Резултати</h1>
			<h2>
				Lorem ipsum odor amet, consectetuer adipiscing elit. Ac purus in massa egestas mollis varius;
				dignissim elementum. Mollis tincidunt mattis hendrerit dolor eros enim, nisi ligula ornare.
				Hendrerit parturient habitant pharetra rutrum gravida porttitor eros feugiat. Mollis elit
				sodales taciti duis praesent id. Consequat urna vitae morbi nunc congue.
			</h2>
		</div>
	)
}

export default Project;