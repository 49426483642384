import React from 'react';
import "./Sights.css";
import { Link } from 'react-router-dom';
import themes from '../../data/themes/themes';

const Themes = () => {
	const themeStyle = (src) => ({
		backgroundImage: 'url(' + src + ')',
		backgroundPosition: 'center'
	})
	return (
	<div className="Themes">
		<ul className="Themes-list">
			{themes.map((theme, id) => (
				<Link to={{ pathname: `/themes/0`, state: { data: theme } }}>
					<li className="Theme-item" style={themeStyle(theme.img)}>
						<h2>{theme.name}</h2>
					</li>
				</Link>
			))}
		</ul>
	</div>
	)
}

export default Themes;